import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/nuohost-1-min-1.webp";
import projImg2 from "../assets/img/pedia-buddy-min.webp";
import projImg3 from "../assets/img/dev-kritoni-1-min-1.webp";
import projImg4 from "../assets/img/inline.avif";
import projImg5 from "../assets/img/Thumb 590x300.avif";
import projImg6 from "../assets/img/Banner.avif";

import projImg7 from "../assets/img/new_banner1.png";
import projImg8 from "../assets/img/new_banner2.png";
import projImg9 from "../assets/img/new_banner3.png";
import projImg10 from "../assets/img/new_banner4.png";
import projImg11 from "../assets/img/new_banner5.png";
import projImg12 from "../assets/img/new_banner6.png";
import projImg13 from "../assets/img/new_banner7.png";
import projImg14 from "../assets/img/new_banner8.png";



import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Projects = () => {
  const projects = [


    {
      title: "Book Auto Moto",
      description: "Book Auto Moto is a platform that connects people with the best auto repair shops in their area.",
      imgUrl: projImg7,
    },

    {
      title: "Flip Load Guide",
      description: "Here you can find the best guide for Flip Load.",
      imgUrl: projImg8,
    },

    {
      title: "Bloomu Dating App",
      description: "Bloomu is a dating app that connects people with best matches.",
      imgUrl: projImg9,
    },

    {
      title: "Masjid Qibla and Quran",
      description: "Get the direction of Qibla and read Quran with this app.",
      imgUrl: projImg10,
    },

    {
      title: "Hindi Quran",
      description: "Read Quran in Hindi with this app.",
      imgUrl: projImg11,
    },
    {
      title: "Kasuwa Ecommerce",
      description: "Kasuwa is an ecommerce platform that connects people with the best products.",
      imgUrl: projImg12,
    },

    {
      title: "Asam Board Solutions",
      description: "Get questions and answers to conquer your exams.",
      imgUrl: projImg13,
    },
    {
      title: "Dev Kirtonia",
      description: "Empowering Retail Therapy: Find Joy in Every Click this Dynamic Ecommerce Marketplace.",
      imgUrl: projImg3,
    },
    {
      title: "Jaanu",
      description: "Where Love Meets Technology: Experience a Digital Love Story on Our Innovative Dating App.",
      imgUrl: projImg4,
    },
    {
      title: "Casino Game",
      description: "Unleash the Thrill, Play to Win: Dive into a World of Excitement on Our Ultimate Casino Game Platform.",
      imgUrl: projImg5,
    },
    {
      title: "FunFlix",
      description: "Your Passport to Extraordinary Worlds: Explore, Discover, and Escape with Our Premier Streaming Platform.",
      imgUrl: projImg6,
    },

  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Portfolio</h2>
                  <p>
                    Our Goal : Crafting Digital Marvels: Elevating Brands with Stunning Websites and Immersive Apps, Ensuring Unmatched Client Satisfaction.
                  </p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      {/* <Nav.Item>
                        <Nav.Link eventKey="first">Portfolio</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Our Teams</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Be with us!</Nav.Link>
                      </Nav.Item> */}
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>
                          {projects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Cumque quam, quod neque provident velit, rem
                          explicabo excepturi id illo molestiae blanditiis,
                          eligendi dicta officiis asperiores delectus quasi
                          inventore debitis quo.
                        </p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  );
};
